




import { Component, Vue } from "vue-property-decorator";

@Component
export default class GocardlessSuccessful extends Vue {
  public name = "GocardlessSuccessful";
}
